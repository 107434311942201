<template>
  <div>
    <!-- <div class="login_top">
      <div class="logintop_min">
        <div class="min_top">
          <div class="top_img1">
            <img src="../../assets/logo.png" alt="" />
          </div>
          <div class="top_img2">
            <img src="../../assets/平台名称.png" alt="" />
          </div>
         <div class="geren">
            <div class="gerenone">
                <img src="../../assets/geren.png" alt="">
        
            欢迎您：xxxx用户|
        
              <img src="../../assets/text.png" alt="">
        
              <img src="../../assets/black.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div> -->
  
  <Top></Top>
  </div>
</template>
<script>
import Top from "../../components/top/index.vue"
export default {
   components: {
    Top
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
}

.login_top {
  height: 160px;
  width: 100%;
  background-image: url(../../assets/头部底图.png);
  background-size: 100% 100%;
}
.logintop_min {
  width: 1200px;
  margin: auto;
}
.min_top {
  height: 160px;
  line-height: 160px;
  display: flex;
}
.top_img1 {
  display: flex;
  align-items: center;
}
.top_img2 {
  display: flex;
  align-items: center;
  margin-top: 30px;
  padding-left: 20px;
}
.gerenone{
    /* width: 222px; */
    height: 40px;
    display: flex;
    background-color: #fff;
    margin-top: 110px;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    opacity: 0.8;
}
.gerenone img{
    width: 20px;
    height: 20px;
    padding: 0 10px;
}
</style>